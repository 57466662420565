import React, { useCallback, useState } from "react";
import Home from "../Home";
import {
  equalTo,
  orderByChild,
  push,
  query,
  ref,
  get,
  limitToLast,
} from "firebase/database";
import { fdb } from "../../../config/firebase";
import { useNavigate } from "react-router";
export interface ErrorState {
  name: string;
  phone: string;
  birthDate: string;
  gender: string;
}
export type UserInfo = {
  name: string;
  phone: string;
  gender: "man" | "girl" | undefined;
  birthDate: string;
};
const HomeContainer = () => {
  const navigate = useNavigate();

  const [data, setdata] = useState<UserInfo>({
    name: "",
    phone: "",
    gender: undefined,
    birthDate: "",
  });
  const [check, setCheck] = useState<boolean>(false);

  const [error, setError] = useState<ErrorState>({
    name: "",
    phone: "",
    birthDate: "",
    gender: "",
  });
  const formatPhoneNumber = (value: string) => {
    const numbers = value.replace(/[^\d]/g, "");
    let phoneFormatted = "";

    if (numbers.length <= 3) {
      phoneFormatted = numbers;
    } else if (numbers.length <= 7) {
      phoneFormatted = `${numbers.slice(0, 3)}-${numbers.slice(3, 7)}`;
    } else {
      phoneFormatted = `${numbers.slice(0, 3)}-${numbers.slice(
        3,
        7
      )}-${numbers.slice(7, 11)}`;
    }
    return phoneFormatted;
  };

  const UpdateData = useCallback((type: string, value: any) => {
    setdata((data) => {
      let obj = Object.assign({}, data);
      if (type === "phone") {
        obj.phone = formatPhoneNumber(value);
        return obj;
      }
      if (type === "birth") {
        if (value.length <= 8) {
          obj.birthDate = value;
        }
      }
      if (type === "name") {
        obj.name = value;
      }
      if (type === "gender") {
        obj.gender = value;
      }
      if (type === "gender") {
        obj.gender = value;
      }
      if (type === "check") {
        setCheck(true);
      }

      return obj;
    });
  }, []);

  const AllInputFilled = (): boolean => {
    return (
      data.name.trim() !== "" &&
      data.phone.trim() !== "" &&
      data.birthDate.trim() !== "" &&
      data.name.length >= 2 &&
      data.name.length <= 6 &&
      data.phone.length === 13 &&
      data.birthDate.length === 8 &&
      data.gender !== undefined &&
      data.gender.length > 0 &&
      check === true
    );
  };
  const AccountSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (AllInputFilled()) {
      const dbRef = query(
        ref(fdb, "/users"),
        orderByChild("phone"),
        equalTo(data.phone)
      );
      const numberRef = query(
        ref(fdb, "/users"),
        limitToLast(1)
      );

      await get(dbRef).then(async (snapshot) => {
        if (snapshot.exists()) {
          setError((prevState) => ({
            ...prevState,
            phone: "이미 가입된 휴대전화입니다",
          }));
          return;
        } else {
          const num = await get(numberRef);
          let number = 0;
          if (num.exists()) {
            num.forEach((item) => {
              number = item.val().number;
            });
          }
          try {
            push(ref(fdb, "/users"), {
              name: data.name,
              phone: data.phone,
              birth: data.birthDate,
              gender: data.gender,
              agreement: check,
              timestamp: Date.now(),
              number: number + 1,
            });
            navigate("/account/sucess");
          } catch (error) {
            console.log("error", error);
          }
        }
      });
    } else {
      const newErrors = {
        name:
          data.name.length <= 0 || data.name.length < 2
            ? "이름을 입력해주세요"
            : "",
        phone: data.phone.length <= 0 ? "휴대전화를 입력해주세요" : "",
        birthDate: data.birthDate.length <= 0 ? "생년월일을 입력해주세요" : "",
        gender:
          data.gender === undefined || data.gender?.length < 0
            ? "성별을 입력하세요"
            : "",
      };
      setError(newErrors);
    }
  };

  return (
    <Home
      data={data}
      check={check}
      AccountSubmit={AccountSubmit}
      setCheck={setCheck}
      AllInputFilled={AllInputFilled}
      UpdateData={UpdateData}
      error={error}
    />
  );
};
export default HomeContainer;
