import "./css/index.scss"
const AccountSucess = () => {
    return (
        <main className="sucess-main-page">
            <div className="wrapper">
                <div className="img-container">
                    <img src="/assets/home/greenagelogo.png"
                        srcSet='
                            /assets/home/greenagelogo@2x.png ,
                            /assets/home/greenagelogo@3x.png '
                        alt="greenage" />
                </div>
                <div className="title-container">
                    <p>그린에이지</p>
                    <p>회원가입이 완료되었습니다</p>
                </div>
                <div className="subtitle-container">
                    <p>그린에이지 채널톡 추가하고</p>
                    <p>최대 30% 할인 쿠폰을 만나보세요</p>
                </div>
                <div className="kakaotalk-btn">
                    <a href="http://pf.kakao.com/_ZxjMXG">
                        <img src="/assets/home/kakaobtn.png"
                            srcSet='
                            /assets/home/kakaobtn@2x.png ,
                            /assets/home/kakaobtn@3x.png '
                            alt="greenage" />
                    </a>
                </div>
            </div>

        </main>
    )
}

export default AccountSucess;