import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import "./css/index.css";
import { ErrorState, UserInfo } from "./Containers/HomeContainer";
type Props = {
    AccountSubmit: (event: React.SyntheticEvent) => void;
    AllInputFilled: () => boolean;
    check: boolean;
    error: ErrorState;
    setCheck: React.Dispatch<React.SetStateAction<boolean>>;
    data: UserInfo;
    UpdateData: (type: string, value: any) => void;
}
const Home = ({
    AccountSubmit,
    check,
    AllInputFilled,
    error,
    data,
    UpdateData
}: Props) => {
    const { name, phone, birthDate, gender } = data
    const layouts = [
        {
            title: "이름",
            type: "text",
            placeholder: "내용을 입력해주세요",
            length: 6,
            errorText: name.length <= 0 ? error.name : '',
            dispatch: "name",
            value: name
        },

        {
            title: "휴대전화",
            subtitle: "-없이 숫자만 입력 (예:01012341234)",
            type: "text",
            placeholder: "내용을 입력해주세요",
            length: 13,
            errorText: phone.length <= 0 ? error.phone : error.phone,
            dispatch: "phone",
            value: phone
        },

        {
            title: "생년월일",
            type: "number",
            placeholder: "내용을 입력해주세요",
            subtitle: '생년월일 6자리 (예:19881225)',
            length: 8,
            errorText: birthDate.length <= 0 ? error.birthDate : '',
            dispatch: "birth",
            value: birthDate
        },

    ]
    return (
        <main className="home-page-main">
            <div className="wrapper">
                <div className="main-img-container">
                    <img src="/assets/home/greenagelogo.png"
                        srcSet='
                            /assets/home/greenagelogo@2x.png ,
                            /assets/home/greenagelogo@3x.png '
                        alt="greenage" />
                </div>
                <form className="form-container">
                    {layouts.map(({ title, type, subtitle, placeholder, length, errorText, dispatch, value }, idx) => {
                        return <Input
                            key={idx}
                            title={title}
                            type={type}
                            placeholder={placeholder}
                            subtitle={subtitle}
                            onChangeInput={UpdateData}
                            textLength={length}
                            dispatch={dispatch}
                            value={value}
                            errorText={errorText}
                        />
                    })}
                    <div className="gender-choice-container">
                        <div className="title-container">
                            <div className="label-title">성별</div>
                            <div className="error-text">{gender === undefined ? error.gender : ''}</div>
                        </div>
                        <div className="gender-btn-container">
                            <Button
                                type='button'
                                title='남'
                                isCheck={gender === 'man'}
                                valueClick={UpdateData}
                                value="man"
                                dispatch="gender"
                            />
                            <Button
                                type='button'
                                title='여'
                                isCheck={gender === 'girl'}
                                valueClick={UpdateData}
                                value="girl"
                                dispatch="gender"
                            />
                        </div>
                    </div>
                    <button
                        className={`agreement-check ${check && 'agreement-check-box'}`}
                        type="button"
                        onClick={() => UpdateData('check', true)}
                    >
                        <div className="check-box">
                            {check && <img src='/assets/home/check.svg' />}
                        </div>
                        개인정보 수집 및 이용동의
                    </button>

                    <button
                        className={`submit-button ${AllInputFilled() && 'submit-enabled'}`}
                        disabled={!AllInputFilled() && true}
                        onClick={AccountSubmit}
                    >
                        그린에이지 회원가입
                    </button>
                </form>
            </div>
        </main>
    )
}


export default Home;