// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAh7dDTxvtTTfgSXzCKza1Eh7RNgZfIf9Y",
    authDomain: "greenage-1d6e9.firebaseapp.com",
    projectId: "greenage-1d6e9",
    storageBucket: "greenage-1d6e9.appspot.com",
    messagingSenderId: "786385130096",
    appId: "1:786385130096:web:a99cc92dfb3442f7bc9ab5",
    measurementId: "G-TQPLNDNMBF",
    databaseURL: `https://greenage-1d6e9-default-rtdb.asia-southeast1.firebasedatabase.app/`,
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const fdb = getDatabase(app);
// export const db = getFirestore(app);

