import "./css/index.css";
type Props = {
    title: string;
    type: string;
    placeholder: string;
    subtitle?: string | null;
    onChangeInput: (type: string, value: any) => void;
    value?: string;
    textLength?: number;
    errorText?: string | null;
    dispatch:string
    
};
const Input = ({ title, type,dispatch, placeholder, subtitle, onChangeInput, value, textLength, errorText }: Props) => {
    return (
        <div className="input-wrapper">
            <div className="title-container">
                <div className="title">{title}</div>
                <div className={`sub-title ${errorText && 'error-text'}`}>{errorText ? errorText : subtitle}</div>
            </div>
            <input
                type={type}
                placeholder={placeholder}
                onChange={(e)=>{
                    onChangeInput(dispatch,e.target.value)
                }}
                value={value}
                maxLength={textLength}
            />
        </div>
    )
}

export default Input;